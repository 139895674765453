// Instrument
import { types } from './types';
import update from 'immutability-helper';

const initialState = {
    redactorAutosaveTimestamp: 0
};

export const redactorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_AUTOSAVE_TIMESTAMP:
            return update(state, {redactorAutosaveTimestamp: {$set: payload}});

        default:
            return state;
    }
};
