// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import {api, getKeys, authStr, getRedactorAutosaveTimestamp, getComments} from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import {redactorActions} from "../../actions";
import moment from "moment/moment";
import {isNil} from "ramda";
import { projectActions } from "../../../project/actions";

export function* callRedactorAutosaveCommentWorker ({ payload: {project_id, data }}) {
    try {

        // Ограничение для авто сохранения (в секундах)
        if(isNil(data.timeLimit) || data.timeLimit) {
            let lastTimestamp = yield select(getRedactorAutosaveTimestamp);
            let timePassed = moment().unix() - lastTimestamp;

            if (timePassed < 10) {
                return;
            }
        }

        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const url = `${api}/${publicKey}/v1/projects/motion/${project_id}/comments/autosave`
        const response = yield call(fetch, url, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({...data, account_uid: accountId}),
        });
        const { response: { status, comment }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(redactorActions.setAutosaveTimestamp(moment().unix()));

        if(status === 'deleted') {
            yield put(projectActions.deleteCommentSuccess(comment.id));

        }
        else if (status === 'saved') {
            yield put(projectActions.createCommentSuccess(comment));
        }
        else {}

    } catch (error) {
        //yield put(modalsActions.setModalStatus('fail'));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
