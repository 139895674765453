// Core
import {asyncTypes} from './asyncTypes';

export const redactorActionsAsync = Object.freeze({
    autosaveRedactorCommentAsync: (project_id, data) => ({
        type: asyncTypes.AUTOSAVE_COMMENT_ASYNC,
        payload: {
            project_id,
            data
        },
    }),
});
