// Core
import {useSelector, useDispatch} from 'react-redux';

// Actions

import {redactorActionsAsync} from "../bus/redactor/saga/asyncActions";

export const useRedactor = () => {
    const selector = (state) => state.redactor;
    const redactor = useSelector(selector);
    const dispatch = useDispatch();

    const autosaveRedactorCommentAsync = (url, data) => {
        dispatch(redactorActionsAsync.autosaveRedactorCommentAsync(url, data));
    };

    return {
        ...redactor,
        autosaveRedactorCommentAsync
    };
};
