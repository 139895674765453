// Core
import {useEffect, useRef} from "react";

// Hooks
import {useRedactor} from "../../../hooks/useRedactor";

// Instruments
import {_log} from "../../../helpers/helpers";
import {isNil} from "ramda";

const Redactor = ({redactorOptions, projectId, fileId, initialText}) => {
    const editorRef = useRef(null); // useRef создаёт ссылку на DOM-элемент
    const {autosaveRedactorCommentAsync} = useRedactor();

    useEffect(() => {
        let options = {
            styles: false,
            source: false,
            linkSize: 500,
            buttonsHide: ['deleted', 'lists', 'format', 'bold', 'italic', 'link'],
            minHeight: '120px',
            callbacks: {
                changed: function (text) {
                    autosaveFunction(text, '', true);
                },
                blur: function () {
                    autosaveFunction(this.editor.getElement().html(), '', false);
                }
            },
        };
        if (!isNil(redactorOptions)) {
            options = {...options, ...redactorOptions};
        }

        const autosaveFunction = (text, commentElement, timeLimit) => {
            autosaveRedactorCommentAsync(
                projectId,
                {
                    commentText: text,
                    fileId: fileId,
                    timeLimit
                });
        }

        if (editorRef.current) {
            window.$R(editorRef.current, options); // Инициализируем Imperavi Redactor
        }

        return () => {
            if (editorRef.current) {
                _log('Редактор есть. Пробуем его удалить');
                try {
                    _log('Удаляем');
                    window.$R(editorRef.current, "destroy"); // Уничтожаем редактор при размонтировании
                } catch (e) {
                    console.error("Ошибка при удалении Redactor:", e);
                }
            }
        };
    }, []); // useEffect вызывается только один раз

    return <div><textarea ref={editorRef} defaultValue={initialText}></textarea></div>;
};

export default Redactor;