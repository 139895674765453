// Core
import {takeEvery} from 'redux-saga/effects';

// Instruments
import {asyncTypes} from './asyncTypes';
import {callRedactorAutosaveCommentWorker} from "./workers/redactorAutosaveComment";

export const redactorWatchers = Object.freeze({
    * watchAutosaveComment() {
        yield takeEvery(asyncTypes.AUTOSAVE_COMMENT_ASYNC, callRedactorAutosaveCommentWorker);
    },
});
